import React from "react";

import { Button } from '@mui/material';
import { Save } from '@mui/icons-material';
import { Done } from '@mui/icons-material';

export default function SaveChangesButton(props) {
    return (
        <>
            {
                props.changes ? (
                    <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        onClick={() => {props.saveChanges()}}
                    >
                        <Save/>
                        Save Changes
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        disabled
                    >
                        <Done/>
                        Up to Date!
                    </Button>
                )
            }

        </>
    )
}