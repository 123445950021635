import YourInfo from "./modules/forms/YourInfo";
import CompanyInfo from "./modules/forms/CompanyInfo";
import Apps from "./modules/forms/Apps";
import Style from "./modules/forms/Style";
import Summary from "./modules/forms/Summary";

const steps = [
    { name: "Admin Info" },
    { name: "Company Info" },
    { name: "Apps" },
    { name: "Style" },
    { name: "Summary" }
];

const getForm = (step, props) => {
    switch (step) {
        case 0:
            return <YourInfo {...props} />
        case 1:
            return <CompanyInfo {...props} />
        case 2:
            return <Apps {...props} />
        case 3:
            return <Style {...props} />
        case 4:
            return <Summary {...props} />
        default:
            return <p>Stage Not Found - Please Refresh</p>
    }
}

export { steps, getForm };