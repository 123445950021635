import React from "react";
import { Step, Stepper, StepLabel, Paper } from "@mui/material";
import { steps } from "../constants";

export default function StepperBox(props) {
    return (
        <Paper style={{ padding: "10px", width: "100%" }}>
            <Stepper activeStep={props.step} alternativeLabel>
                {steps.map((s, key) => {
                    return (
                        <Step 
                            key={key}
                            sx={{
                                '& .MuiStepLabel-root .Mui-completed': {
                                  color: '#24356a', // circle color (COMPLETED)
                                },
                                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                  {
                                    color: 'grey.500', // Just text label (COMPLETED)
                                  },
                                '& .MuiStepLabel-root .Mui-active': {
                                  color: '#24356a', // circle color (ACTIVE)
                                },
                                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                  fill: 'white', // circle's number (ACTIVE)
                                },
                              }}
                        >
                            <StepLabel>{s.name}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Paper>
    )
}