import react from "react";
import { AppBar, Container, Drawer, Grid, LinearProgress, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import TagFacesIcon from '@mui/icons-material/TagFaces';

export default function PreviewWindow(props) {
    const previewTab = {
        background: props.data.sidebar.background, 
        color: props.data.sidebar.font,
        width: "40%",
        textAlign: "center"
    }

    const previewActiveTab = {
        background: props.data.sidebar.active_background, 
        color: props.data.sidebar.active_font,
        width: "40%",
        textAlign: "center",
        fontWeight: "bold",
        border: "none"
    }

    return props.data !== undefined ? (
        <Container>
            <AppBar 
                style={{
                    background: props.data.topMenu.background
                }}
                position="static"
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {
                            props.data.logo !== undefined ? (
                                <img style={{
                                        background: "white",
                                        borderRadius: "20px",
                                        padding: "5px",
                                        marginRight: "10px"
                                    }}
                                    width="171" 
                                    height="45" 
                                    src={`${props.data.logo.base64}`}
                                />
                            ) : (
                                <TagFacesIcon sx={{ display: { md: 'flex' }, alignItems: "center", color: props.data.topMenu.font}} />
                            )
                        }
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: { md: 'flex' },
                                color: props.data.topMenu.font
                            }}
                        >
                            PREVIEW
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
            <Tabs
                orientation="vertical"
                value={1}
            >
                <Tab style={ previewTab } label="Dashboard"/>
                <Tab style={ previewTab } label="Customers"/>
                <Tab style={ previewTab } label="Personnel"/>
                <Tab style={ previewActiveTab } value={1} label="Apps"/>
            </Tabs>

        </Container>
    ) : (
        <div style={{ textAlign: "center" }}>
            <LinearProgress />
            <p>Loading Preview...</p>
        </div>
    )
}