import React, { useEffect, useState } from "react";

import { LinearProgress, Grid, Alert } from "@mui/material";
import AppSelector from "./formElement/appSelector";
import SaveChangesButton from "../common/saveChangesButton";
import { appsIndex } from "../../api/appService";
import HelpTip from "../common/helpTip";
import {appsTip} from "../../assets/Tips";

export default function Apps(props) {
    const [ apps, setApps ] = useState([]);
    const [ selectedApps, setSelectedApps ] = useState({});
    const [ total, setTotal ] = useState("0.00");
    const [ errors, setErrors ] = useState(false);
    const [ loading, setLoading ] = useState({ status: true, message: "Loading Apps. Please Hold On...." });
    const [ changes, setChanges ] = useState(props.changes);

    const getApps = async () => {
        const a = await appsIndex();

        if(a.data !== undefined) {
            setApps(a.data);
        }
        else {
            setErrors({ element: "Apps List", message: "Failed to load available apps. Please refresh your page and try again" });
        }

        a.data.map((app) => {
            if(app.core) {
                updateApp(app.id, {
                    price: app.price,
                    banded_cost: false,
                    users: 0,
                    name: app.name
                });
            }
        });
        setLoading({status: false, message: ""});
    }

    const updateApp = async (app_id, costInfo) => {
        const localApps = {...selectedApps};
        var t = parseFloat( total );

        if( localApps[app_id] !== undefined ) {
            t = t - localApps[app_id].price;
        }

        localApps[app_id] = { id: app_id, ...costInfo };
        setTotal( (t + parseFloat( localApps[app_id].price )).toFixed(2) );
        setSelectedApps(localApps);
        setChanges(true);
        props.setChanges(true);
    }

    const removeApp = async (app_id) => {
        const localApps = {...selectedApps};
        if( localApps[app_id] !== undefined ) {
            setTotal( (total - localApps[app_id].price).toFixed(2) );
            delete localApps[app_id];
        }

        setSelectedApps(localApps);
        setChanges(true);
        props.setChanges(true);
    }

    const saveChanges = () => {
        var s = {...selectedApps};
        props.setSelectedApps(s);

        setChanges(false);
        props.setChanges(false);
    }

    useEffect(() => {
        getApps();
    }, [])

    useEffect(() => {
        setSelectedApps(props.selectedApps);
        let tempTotal = 0;
        let keys = Object.keys(props.selectedApps)
        for( let i = 0; i < keys.length; i++ ) {
            try {
                if(props.selectedApps[keys[i]].price !== undefined) tempTotal += parseFloat(props.selectedApps[keys[i]].price);
            } catch (error) {
                setTotal("There has Been an Error, Please Try Again");
                return;
            }
        }

        setTotal(tempTotal.toFixed(2));
    }, [props.selectedApps])

    return loading.status ? (
        <div style={{ textAlign: "center" }}>
            <LinearProgress />
            <p>{loading.message}</p>
        </div>
    ) : (
        <>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={8}>
                <h3>Apps:</h3>
            </Grid>
            <Grid item xs={1}>
                <HelpTip tip={appsTip} />
            </Grid>
            <Grid item xs={3}>
                <SaveChangesButton
                    changes={changes}
                    saveChanges={saveChanges}
                />
            </Grid>

            {
                errors && (
                    <>
                        <Grid item xs={12}>
                            <Alert severity="error">{errors.message}</Alert>
                        </Grid>
                    </>
                )
            }
        </Grid>
        
        <Grid container spacing={3}>
        {
            apps.map((app, key) => {
                return (
                    <Grid item xs={12} md={4} key={key}>
                        <AppSelector 
                            app={app}
                            appSelectData={props.selectedApps[app.id]}
                            updateApp={updateApp}
                            removeApp={removeApp}
                        />
                    </Grid>
                )
            })
        }
        </Grid>
        <p style={{
            textAlign: "center",
            backgroundColor: "green",
            color: "white",
            padding: "10px",
            margin: "10px 2px 10px 2px",
            borderRadius: "10px"
        }}>Grand-Total: £{total}</p>
        <SaveChangesButton
            changes={changes}
            saveChanges={saveChanges}
        />
        </>
    )
}