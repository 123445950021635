const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

const yourInfoValidator = (payload) => {
    const check = {};

    if( payload.fullName === undefined || payload.fullName === "" ) {
        check.fullName = "Fullname is required";
    } else if( payload.fullName.length > 255 ) {
        check.fullName = "Fullname exceeds character limit of 255 characters";
    }

    if( payload.email === undefined || payload.email === "" ) {
        check.email = "Email is required";
    } else if( emailRegex.exec(payload.email) == null ) {
        check.email = "Invalid Email Format <name>@<domain>";
    }
    
    return check;
}

export {
    yourInfoValidator
};