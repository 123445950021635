import './App.css';

import Particles from "react-tsparticles";
import { getParticleConfig } from "./assets/ParticleConfig";
import { loadFull } from "tsparticles";

import AppBody from "./modules/AppBody";
import EyeCatcher from "./modules/EyeCatcher";
import { useState, Suspense } from 'react';
import { CircularProgress, Fade } from '@mui/material';

function App() {
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [formStart, setFormStart] = useState(false);
  const particlesInit = async (main) => {
    await loadFull(main);
  }

  const loader = (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "80vw", height: "60vh" }}>
      <CircularProgress />
      <p>Loading, Please Wait...</p>
    </div>
  );

  return (
    <div>
    <Particles id="background" className="background-container" options={getParticleConfig(backgroundColor)} init={particlesInit}/>
    {
      formStart ? (
        <Suspense fallback={loader}>
          <Fade in={true} timeout={3000}>
            <div>
              <AppBody setBackgroundColor={setBackgroundColor}/>
            </div>
          </Fade>
        </Suspense>
      ) : (
        <Suspense fallback={loader}>
          <EyeCatcher setBackgroundColor={setBackgroundColor} setFormStart={setFormStart} />
        </Suspense>
      )
    }
    </div>
  );
}

export default App;
