const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

const companyInfoValidator = (payload) => {
    const check = {};

    if( payload.name === undefined || payload.name === "" ) {
        check.name = "Company Name is required";
    } else if( payload.name.length > 255 ) {
        check.name = "Company Name cannot exceed 255 characters";
    }

    if( payload.domain_name === undefined || payload.domain_name === "" ) {
        check.domain_name = "Domain Name is Required";
    } else if( payload.length > 255 ) {
        check.domain_name = "Domain Name cannot exceed 255";
    }

    if( payload.billing_email === undefined || payload.billing_email === "" ) {
        check.billing_email = "Billing Email is required";
    } else if( emailRegex.exec(payload.billing_email) == null ) {
        check.billing_email = "Invalid Email format <name>@<domain>";
    }

    if( payload.address !== undefined && payload.address.length > 255 ) {
        check.address = "Address cannot exceed 255 characters";
    }

    return check;
}

export {
    companyInfoValidator
};