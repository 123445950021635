import config from "../config";

const appsIndex = async () => {
    const options = {
        base: config.appsServiceBase,
        params: false,
        payload: false,
        endpoint: `/api/app`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
    });

    return await data.json();
}

const appShow = async () => {

}

const modulesIndex = async () => {

}

const modulesShowDirectories = async () => {

}

const themesIndex = async () => {
    const options = {
        base: config.appsServiceBase,
        params: false,
        payload: false,
        endpoint: `/api/theme`,
        method: "GET"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
    });

    return await data.json();
}

export {
    appsIndex,
    appShow,
    modulesIndex,
    modulesShowDirectories,
    themesIndex
};