import React, { useState } from "react";

import FormBox from "./FormBox";
import StepperBox from "./StepperBox";
import StepperControlBox from "./StepperControlBox";
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';

import { requestStore } from "../api/tenantMiddleware";
import { supportEmail } from "../config";

import { Container, Stack, Paper, CircularProgress, Grid, Button } from "@mui/material";


export default function AppBody(props) {
    const [data, setData] = useState(false);
    const [step, setStep] = useState(0);
    const [changes, setChanges] = useState(false);
    const [loading, setLoading] = useState(false);
    const [existingUser, setExistingUser] = useState(null);
    const [error, setError] = useState(false);

    const changeStep = async (newStep) => {
      var answer = true;

      if(changes) {
        answer = window.confirm("There Are Unsaved Changes - Are You Sure You Wish To Continue?");
      }

      if(answer) {
        setChanges(false);
        setStep(newStep);
      }
    }

    const handleSave = async (payload) => {
      setLoading(true);
      setData(payload);

      try {
        const res = await requestStore(payload);

        if(res.status !== undefined && res.status == 200) {
          setTimeout(() => {
            props.setBackgroundColor("#005500")
            setLoading(false)
          }, 5000);
        } else {
          setTimeout(() => {
            props.setBackgroundColor("#550000")
            setLoading(false);
            setError("Request Processing Error")
          }, 5000);
        }
      }
      catch(err) {
        setTimeout(() => {
          props.setBackgroundColor("#550000")
          setLoading(false)
          setError("Request Communication Error")
        }, 5000);
      }

    }

    return (
        <Container fixed className="container">
          <Stack direction="column" justifyContent="space-evenly" alignItems="center" className="grid-container">
              <div className="app-header-box">
                <h1 style={{ color: "white" }}>MYBE</h1>
              </div>
              {
                existingUser == null && (
                  <Paper style={{ width: "50%", padding: "20px", textAlign: "center" }}>
                    <img src="https://mybe-public.s3.eu-west-2.amazonaws.com/unity-logo.png" alt="Unity Logo" />
                    <p>Do You Have A MYBE Unity Portal Account?</p>
                    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: "20px" }}>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          style={{ background: "#24356a" }}
                          onClick={() => {setExistingUser(true); props.setBackgroundColor("#005500")}}
                        >
                          Yes
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          style={{ background: "#24356a" }}
                          onClick={() => {setExistingUser(false)}}
                        >
                          No
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                )
              }
              {
                existingUser != null && existingUser && (
                  <Paper style={{ width: "50%", padding: "20px", textAlign: "center" }}>
                    <img src="https://mybe-public.s3.eu-west-2.amazonaws.com/unity-logo.png" alt="Unity Logo" />
                    <p>Excellent!<br/> Please contact a member of our team at <a href={`mailto:${supportEmail}?subject=MYBE Account Conversion`}>{supportEmail}</a> so that we may convert your account to an Enterprise one.</p>
                  </Paper>
                )
              }
              {
              existingUser !== null && !existingUser && (
                !data ? (
                  <>
                    <div className="stepper-box" style={{ width: "80%" }}>
                      <StepperBox step={step} />
                    </div>          
                    <div className="form-box">
                      <FormBox 
                        step={step}
                        setStep={changeStep}
                        changes={changes}
                        setChanges={setChanges}
                        handleSave={handleSave}
                        setBackgroundColor={props.setBackgroundColor}
                      />
                    </div>
                    <div className="stepper-control-box">
                      <StepperControlBox
                        step={step}
                        setStep={changeStep}
                        changes={changes}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Paper>
                      {
                        loading ? (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <CircularProgress size={120}/>
                            <p>Processing Your Details. Please Wait...</p>
                          </div>
                        ) : (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <DoneIcon sx={{ fontSize: 82 }}color="success"/>
                            <p>Thank For Signing Up For MYBE! <br/>A Member Of Our Team Will Contact You Shortly To Finalise Your Package.</p>
                          </div>
                        )
                      }

                    </Paper>
                  </>
                )
              )
            }
            {
              error && (
                <>
                  <Paper>
                    <div style={{ textAlign: "center" , padding: "20px" }}>
                      <ErrorIcon sx={{ fontSize: 82, color: "red" }} />
                      <p>{error}</p>
                      <p>Sorry something has gone wrong. Please try again or contact a member of our team at: <a href={`mailto:${supportEmail}?subject=MYBE Account Creation Error`}>{supportEmail}</a></p>
                    </div>
                  </Paper>
                </>
              )
            }

          </Stack>
      </Container>
    )
}