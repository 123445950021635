import { Button, CircularProgress, Container, Fade, Grid, Grow, Paper, Stack, Tooltip } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import "../App.css";
import EyeCatcherApps from "./EyeCatcherApps";

export default function EyeCatcher({setBackgroundColor, setFormStart}) {
    const [open, setOpen] = useState(false);
    const [tooltips, setTooltips] = useState(false);

    useEffect(() => {
        setBackgroundColor("#38184C");
        setTimeout(() => {
            setTooltips(true);
        }, 6000);
    }, [setBackgroundColor]);

    const fancyButton = {
        backgroundColor: "#ffffff33",
        borderRadius: "20px",
        padding: "6px",
        width: "60%",
        color: "white",
        margin: "5px",
        fontFamily: "'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell','Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif",
        boxShadow: "2.5px 2.5px 10px rgba(20,20,20,0.5)"
    };

    const loader = (
        <div style={{ display: "flex", flexDirection: "column", width: "80vw", height: "60vh", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
            <p>Loading...</p>
        </div>
    )

    return (
        <Container fixed className="container">
            <Stack direction="column" justifyContent="space-evenly" alignItems="center" className="grid-container">
                <div className="app-header-box">
                    <Fade in={true} timeout={8000}>
                        <h1 style={{ color: "white", fontSize: "64pt" }}>MYBE</h1>
                    </Fade>
                </div>
                <Grow in={true} timeout={8000}>
                    <Paper style={{ width: "60%", padding: "20px", textAlign: "center", backgroundColor: "rgba(255,249,249, 0.23)" }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} md={6}>
                                <Tooltip 
                                    leaveDelay={5000} 
                                    arrow 
                                    open={!open && tooltips}
                                    placement="right"
                                    title="Free Trial Available"
                                >
                                    <Button 
                                        className="eyeCatcherButton"
                                        sx={fancyButton}
                                        onClick={() => {setFormStart(true)}}
                                    >
                                        Register 
                                    </Button>
                                </Tooltip>
                                <Button 
                                    className="eyeCatcherButton"
                                    sx={fancyButton}
                                    onClick={() => {setOpen(true)}}
                                    >
                                    View Available Apps
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grow>
            </Stack>
            {open && (
                <Suspense fallback={loader}>
                    <EyeCatcherApps open={open} setOpen={setOpen} setFormStart={setFormStart} />
                </Suspense>
            )}
            
        </Container>
    )
}