import React, { useEffect, useState } from "react";
import { TextField, Grid, Tooltip, LinearProgress, ButtonGroup, Button, Alert } from "@mui/material";
import FileBase64 from "react-file-base64";
import { themesIndex } from "../../api/appService";
import PreviewWindow from "./formElement/previewWindow";
import SaveChangesButton from "../common/saveChangesButton";
import HelpTip from "../common/helpTip";
import {styleTip} from "../../assets/Tips";
import {styleFileValidator} from "./validators/styleFileValidator";

export default function Style(props) {
    const [presets, setPresets] = useState([]);
    const [data, setData] = useState({sidebar: {}, topMenu: {}});
    const [changes, setChanges] = useState(props.changes);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState({ status: true, message: "Loading Style Presets. Please Hold On...." });

    const getPresets = async () => {
        const t = await themesIndex();

        if(t.data !== undefined) {
            setPresets(t.data);
        } else {
            setErrors({ element: "Presets List", message: "Failed to Load Presets" });
        }

        setLoading({status: false, message: ""});
        if(Object.keys(props.styleInfo.sidebar).length > 0 || Object.keys(props.styleInfo.topMenu).length > 0) return;
        if(t.data.length <= 0) return;
        handlePresetChange(t.data[0]);
    }

    const handleSideChange = (e) => {
        var d = {...data};
        d.sidebar[e.target.name] = e.target.value;
        setData(d);

        setChanges(true);
        props.setChanges(true);
    }

    const handleTopChange = (e) => {
        var d = {...data};
        d.topMenu[e.target.name] = e.target.value;
        setData(d);

        setChanges(true);
        props.setChanges(true);
    }

    const handlePresetChange = (preset) => {
        let sidebar = preset.theme.sidebar;
        let topMenu = preset.theme.top_menu;
        let d = {...data, sidebar, topMenu, presetName: preset.name, preset: preset};

        setLoading({ status: true, message: "Swapping Preset..." });
        setData(d);
        setChanges(true);
        props.setChanges(true);
        setTimeout(() => {
            setLoading({ status: false, message: "" });
        }, 1500);
    }

    const handleUpload = (file) => {
        setErrors(false);

        const check = styleFileValidator(file);
        if(Object.keys(check).length > 0) {
            setErrors({ message: check.file})
            props.setBackgroundColor('#550000');
        } else {
            let d = {...data, logo: file};
            setData(d);
            setChanges(true);
            props.setBackgroundColor(null);
            props.setChanges(true);
        }
    }

    const saveChanges = () => {
        props.setStyleInfo( data );
        setChanges(false);
        props.setChanges(false);
    }

    const nullSwap = (first, second) => {
        try {
            return first !== undefined ? first : second;
        } catch (error) {
            return "#000000";
        }
    }

    useEffect(() => {
        getPresets()
    }, []);

    useEffect(() => {
        setData( props.styleInfo )
    }, [ props.styleInfo ]);

    useEffect(() => {
        setChanges(props.changes);
    }, [ props.changes ]);

    return loading.status ? (
        <div style={{ textAlign: "center" }}>
            <LinearProgress />
            <p>{loading.message}</p>
        </div>
    ) : (
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={8}>
                <h4>Style:</h4>
            </Grid>
            <Grid item xs={1}>
                <HelpTip tip={styleTip} />
            </Grid>
            <Grid item xs={3}>
                <SaveChangesButton
                    changes={changes}
                    saveChanges={saveChanges}
                />
            </Grid>

            {
                errors && (
                    <>
                        <Grid item xs={12}>
                            <Alert severity="error">{errors.message}</Alert>
                        </Grid>
                    </>
                )
            }

            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ textAlign: "center", marginBottom: "10px" }}>
                        <ButtonGroup variant="contained">
                            {
                                presets.map((value, key) => {
                                    return (
                                        <Button 
                                            style={{ 
                                                background: 
                                                    `linear-gradient(
                                                        120deg, 
                                                        ${value.theme.sidebar.background} 0%,
                                                        ${value.theme.sidebar.active_background} 75%
                                                    )`,
                                                color: value.theme.sidebar.font
                                            }}
                                            key={key}
                                            onClick={() => {handlePresetChange(value)}}
                                        >
                                            {value.name}
                                        </Button>
                                    )
                                })
                            }
                        </ButtonGroup>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Grid container spaceing={10} direction="column">
                            <Grid item xs={6}><p>Side Menu:</p></Grid>
                            <Grid item xs={6}>
                                <div style={{ margin: "5px" }}>
                                <Tooltip title={<h3>The background color of the menu items on the left side of the page</h3>} followCursor>
                                <TextField
                                    type="color"
                                    id="sidebar-background"
                                    label="Sidebar Background Color"
                                    name="background"
                                    defaultValue={nullSwap(data.sidebar.background, props.styleInfo.sidebar.background)}
                                    fullWidth
                                    onChange={handleSideChange}
                                />
                                </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ margin: "5px" }}>
                                <Tooltip title={<h3>The color of text in the left menu</h3>} followCursor>
                                <TextField
                                    type="color"
                                    id="sidebar-font"
                                    label="Sidebar Font Color"
                                    name="font"
                                    defaultValue={nullSwap(data.sidebar.font, props.styleInfo.sidebar.font)}
                                    fullWidth
                                    onChange={handleSideChange}
                                />
                                </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ margin: "5px" }}>
                                <Tooltip title={<h3>The color of a menu item in the left menu when it is currently selected</h3>} followCursor>
                                <TextField
                                    type="color"
                                    id="sidebar-active_background"
                                    label="Sidebar Active Background Color"
                                    name="active_background"
                                    defaultValue={nullSwap(data.sidebar.active_background, props.styleInfo.sidebar.active_background)}
                                    fullWidth
                                    onChange={handleSideChange}
                                />
                                </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ margin: "5px" }}>
                                <Tooltip title={<h3>The text color of a menu item in the left menu when it is currently selected</h3>} followCursor>
                                <TextField
                                    type="color"
                                    id="sidebar-active_font"
                                    label="Sidebar Active Font Color"
                                    name="active_font"
                                    defaultValue={nullSwap(data.sidebar.active_font, props.styleInfo.sidebar.active_font)}
                                    fullWidth
                                    onChange={handleSideChange}
                                />
                                </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={6}>Top Menu:</Grid><br/>
                            <Grid item xs={6}>
                                <div style={{ margin: "5px" }}>
                                <Tooltip title={<h3>The background color of the bar that spans the top of the page</h3>} followCursor>
                                <TextField
                                    type="color"
                                    id="top_menu-background"
                                    label="Top Menu Background Color"
                                    name="background"
                                    defaultValue={nullSwap(data.topMenu.background, props.styleInfo.topMenu.background)}
                                    fullWidth
                                    onChange={handleTopChange}
                                />
                                </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ margin: "5px" }}>
                                <Tooltip title={<h3>The color of any text that appear in the bar that spans the top of the screen</h3>} followCursor>
                                <TextField
                                    type="color"
                                    id="top-menu-font"
                                    label="Top Menu Font Color"
                                    name="font"
                                    defaultValue={nullSwap(data.topMenu.font, props.styleInfo.topMenu.font)}
                                    fullWidth
                                    onChange={handleTopChange}
                                />
                                </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ margin: "5px" }}>
                                <Tooltip title={<h3>The background color of any buttons that appear in the top bar. This refers to your notifications and system return buttons</h3>} followCursor>
                                <TextField
                                    type="color"
                                    id="top-menu-button_background"
                                    label="Top Menu Button Background Color"
                                    name="button_background"
                                    defaultValue={nullSwap(data.topMenu.button_background, props.styleInfo.topMenu.button_background)}
                                    fullWidth
                                    onChange={handleTopChange}
                                />
                                </Tooltip>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ margin: "5px" }}>
                                <Tooltip title={<h3>The text color of any buttons that appear in the top bar</h3>} followCursor>
                                <TextField
                                    type="color"
                                    id="top-menu-button_font"
                                    label="Top Menu Button Font Color"
                                    name="button_font"
                                    defaultValue={nullSwap(data.topMenu.button_background, props.styleInfo.topMenu.button_background)}
                                    fullWidth
                                    onChange={handleTopChange}
                                />
                                </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PreviewWindow
                            data={data}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <hr/>
                <Grid container spacing={0.5} justifyContent="space-between" alignItems="center">
                    <Grid item xs={2}>
                        <div><i>Upload Logo</i></div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>
                            <FileBase64 multiple={false} value={data.logo} onDone={(e) => handleUpload(e)} />
                        </div>
                    </Grid>
                    {
                        data.logo !== undefined && (
                            <Grid item xs={6}>
                                <i>{data.logo.name}</i>
                            </Grid>
                        )
                    }
                    <Grid item xs={4}>
                        <div>
                            <i>Max Size 5MB - Accepted Types: .png, .jpeg, .jpg</i>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}