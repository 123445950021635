import { Grid, Button, Paper } from "@mui/material";
import { steps } from "../constants";
import React from "react";

export default function StepperControlBox(props) {

    const prevStep = () => {
        if(props.step > 0) {
            props.setStep(props.step - 1);
        }
    }

    const nextStep = () => {
        if(props.step < steps.length) {
            props.setStep(props.step + 1);
        }
    }

    return (
        <Paper style={{ padding: "10px" }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Button style={{ color: props.changes && props.step > 0 ? "red" : "" }} disabled={ props.step <= 0 } 
                            onClick={() => {prevStep()}} 
                        >
                            Back
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button style={{ color: props.changes && props.step < steps.length - 1 ? "red" : "" }} disabled={ props.step >= steps.length - 1 } 
                    onClick={() => {nextStep()}}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}