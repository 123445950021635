const adminTip = <>
    <h3>Admin Info:</h3>
    <hr/>
    <div style={{ fontSize: "12px" }}>
        <p>When creating your MYBE Package we will generate the first user for you. This user is known as the "Super Admin". They will have full access to all applications and all users. The Super Admin will have the ability to: create, edit, lock and restore any user account including creating other admin accounts.</p>
        <p>To generate this account we require the name, email address and phone number of the user who will become this Super Admin. This information is only used in the creation of the account.</p>
        <p>Once the application has been created the Super Admin may be changed. However, this will require contacting us for security purposes.</p>
    </div>
</>

const companyTip = <>
    <h3>Company Info</h3>
    <hr/>
    <div style={{ fontSize: "12px" }}>
        <p>This form is for entering all the information required by the system for your company. We use this information for billing and management purposes. All fields are required</p>
        <p>Billing Email Address will be used to send you invoices and updates regarding your payment therefore it is important it is kept up to date. This address can be changed from your Portal once your MYBE Package is set up.</p>
    </div>
</>

const appsTip = <>
    <h3>Apps</h3>
    <hr/>
    <div style={{ fontSize: "12px" }}>
        <p>Here you can your own personalised MYBE Package that suits your business needs. Click the blue question mark icon next to any of the app titles to learn more about each application.</p>
        <p>Applications are charged on either a per user basis or a tier usage basis. Clicking on the app more info button will let you know how much an app costs.</p>
        <p>Once you have finished selecting your apps you can review the combined cost at the bottom of the page and save any changes.</p>
        <p>Apps may be added or removed at any time from your MYBE Portal.</p>
        <p><i>All prices are based off a monthly cost</i></p>
    </div>
</>

const styleTip = <>
    <h3>Style</h3>
    <hr/>
    <div style={{ fontSize: "12px" }}>
        <p>Style allow you to customise how your applications will appear once they are up and running. You can change colors to match your company's design pallete or create something entirely new.</p>
        <p>If you are looking for somewhere to start feel free to select one of the presets displayed at the top of the page. Selecting a preset will automatically update all the colors and preview to represent that style.</p>
        <p>As you update and alter colors you will see a preview of your design on the right.</p>
        <p>At the bottom of the form you may upload your company logo. This will always appear in the top left section of the screen. Don't worry if you do not have the logo at the moment. You will be able to update it alongside your style choices at any point from the settings page in your applications.</p>
    </div>
</>

export {
    adminTip,
    companyTip,
    appsTip,
    styleTip
}