import React, { useEffect, useState} from "react";
import { Container, Box, Card } from "@mui/material";
import { getForm } from "../constants";


export default function FormBox(props) {
    const [form, setForm] = useState(<p>Loading...</p>);
    const [adminInfo, setAdminInfo] = useState({});
    const [companyInfo, setCompanyInfo] = useState({});
    const [selectedApps, setSelectedApps] = useState({});
    const [styleInfo, setStyleInfo] = useState({sidebar: {}, topMenu: {}});
    const [trial, setTrial] = useState(true);

    const loadForm = () => {
        var f = getForm(props.step, packageState());
        setForm(f);
    }

    const handleYourInfoUpdate = (payload) => {
        setAdminInfo(payload);
    }

    const handleCompanyInfoUpdate = (payload) => {
        setCompanyInfo(payload);
    }

    const handleSelectedAppsUpdate = (payload) => {
        setSelectedApps(payload);
    }

    const handleStyleUpdate = (payload) => {
        setStyleInfo(payload);
    }

    const handleFormComplete = () => {
        var payload = {
            admin: adminInfo,
            company: companyInfo,
            apps: selectedApps,
            style: styleInfo,
            trial: trial
        };

        props.handleSave(payload);
    }

    const handleTrialChange = (change) => {
        setTrial(true);
    }

    const packageState = () => {
        let payload = {
            adminInfo: adminInfo,
            setAdminInfo: handleYourInfoUpdate,

            companyInfo: companyInfo,
            setCompanyInfo: handleCompanyInfoUpdate,

            selectedApps: selectedApps,
            setSelectedApps: handleSelectedAppsUpdate,

            styleInfo: styleInfo,
            setStyleInfo: handleStyleUpdate,

            changes: props.changes,
            setChanges: props.setChanges,

            handleFormComplete: handleFormComplete,
            handleTrialChange: handleTrialChange,
            trial: trial,
            setBackgroundColor: props.setBackgroundColor
        };

        return payload;
    }

    useEffect(() => {
        loadForm();
    }, [props.step])

    return (
        <Container>
            <Card style={{ padding: "10px" }}>
            <Box>
                { form }
            </Box>
            </Card>
        </Container>
    )
}