import { Tooltip } from "@mui/material";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export default function HelpTip(props) {
    return (
        <div>
            <Tooltip arrow title={props.tip}>
                <QuestionMarkIcon style={{ color: "blue", fontSize: "30px" }}/>
            </Tooltip>
        </div>
    )
}