const acceptedTypes = [
    "image/png",
    "image/bmp",
    "image/jpeg",
    "image/gif"
];

const maxSize = 100000;

const styleFileValidator = (file) => {
    const check = {};

    if(file.type === undefined || file.file === undefined || file.file.size === undefined ) {
        check.file = "Error processing logo, please try uploading again";
        return check;
    }

    if(!acceptedTypes.includes(file.type)) {
        check.file = `Sorry, we do not support ${file.type} type files. Please try a different file.`;
        return check;
    }

    if(file.file.size > maxSize) {
        check.file = `Sorry, your logo is too large at ${file.size}. Please upload something less than 10 mB`;
        return check;
    }

    return check;
};

export {
    styleFileValidator
}