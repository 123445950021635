import React, { useState, useEffect } from "react";
import { TextField, Grid, Tooltip, Alert } from "@mui/material";
import SaveChangesButton from "../common/saveChangesButton";
import HelpTip from "../common/helpTip";
import {yourInfoValidator} from "./validators/yourInfoValidator";
import {adminTip} from "../../assets/Tips";

export default function YourInfo(props) {
    const [ data, setData ] = useState({});
    const [ errors, setErrors ] = useState(false);
    const [ validation, setValidation ] = useState({});
    const [ changes, setChanges ] = useState(props.changes);

    const handleChange = (value, name) => {
        var d = {...data, [name] : value};
        setData(d);

        setChanges(true);
        props.setChanges(true);
    }


    const saveChanges = () => {
        setValidation({});
        setErrors(false);

        const check = yourInfoValidator(data);
        if(Object.keys(check).length > 0) {
            setValidation(check);
            setErrors({message: "Something you entered is not quite right. Please check everything is correct."});
            props.setBackgroundColor('#550000');
        } else {
            props.setBackgroundColor(null);
            props.setAdminInfo( data );
            setChanges(false);
            props.setChanges(false);
        }
    }

    useEffect(() => {
        setChanges(props.changes);
    }, [props.changes]);

    return data !== undefined ? (
        <>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={8}>
                    <h3>Admin Details:</h3>
                </Grid>
                <Grid item xs={1}>
                    <HelpTip tip={adminTip} />
                </Grid>
                <Grid item xs={3}>
                    <SaveChangesButton
                        changes={changes}
                        saveChanges={saveChanges}
                    />
                </Grid>

                {
                    errors && (
                        <>
                            <Grid item xs={12}>
                                <Alert severity="error">{errors.message}</Alert>
                            </Grid>
                        </>
                    )
                }

                <Grid item xs={12}>
                    <Tooltip title={<h3>The fullname of the super admin</h3>} followCursor>
                        <TextField 
                            id="adminFullname"
                            label="Admin Fullname*"
                            name="fullName"
                            error={validation.fullName !== undefined}
                            helperText={validation.fullName}
                            fullWidth
                            defaultValue={props.adminInfo.fullName}
                            // value={data.fullName}
                            onChange={(e) => {handleChange(e.target.value, e.target.name)}}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={<h3>The email address of the super admin. This will be their login name.</h3>} followCursor>
                    <TextField
                        id="adminEmail"
                        label="Admin Email Address*"
                        name="email"
                        error={validation.email !== undefined}
                        helperText={validation.email}
                        defaultValue={props.adminInfo.email}
                        onChange={(e) => {handleChange(e.target.value, e.target.name)}}
                        fullWidth
                    />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Tooltip title={<h3>The phone number of the super admin.</h3>} followCursor>
                    <TextField
                        id="adminPhone"
                        label="Admin Phone Number"
                        name="phone"
                        error={validation.phone !== undefined}
                        helperText={validation.phone}
                        defaultValue={props.adminInfo.phone}
                        onChange={(e) => {handleChange(e.target.value, e.target.name)}}
                        fullWidth
                    />
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    ) : (<p>Loading...</p>)
}