import config from "../config";

const requestStore = async (payload) => {
    const options = {
        base: config.tenantMiddlewareAddressBase,
        params: false,
        payload: payload,
        endpoint: `/public/signup`,
        method: "POST"
    };

    const data = await fetch(`${config.appURL}/api/proxy`, {
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    });

    return await data.json();
}

export {
    requestStore
};