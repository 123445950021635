import React, { useState, useEffect } from "react";
import { TextField, Grid, Tooltip, Alert, Chip } from "@mui/material";
import SaveChangesButton from "../common/saveChangesButton";
import HelpTip from "../common/helpTip";
import LanguageIcon from '@mui/icons-material/Language';
import {companyTip} from "../../assets/Tips";
import { companyInfoValidator } from "./validators/companyInfoValidator";

export default function CompanyInfo(props) {
    const [ data, setData ] = useState({});
    const [ errors, setErrors ] = useState(false);
    const [ validation, setValidation ] = useState({});
    const [ changes, setChanges ] = useState(props.changes);

    const handleChange = (value, name) => {
        var d = {...data, [name] : value};
        if( name === "name" )
        {
            d.domain_name = value.replace(/\s|\W/gm, "-").toLowerCase();
        }
        setData(d)

        setChanges(true);
        props.setChanges(true);
    }

    const saveChanges = () => {
        setValidation({});
        setErrors(false);

        const check = companyInfoValidator(data);
        if( Object.keys(check).length > 0 ) {
            setValidation(check);
            setErrors({ message: "Something you entered is not quite right. Please check everything is correct." });
            props.setBackgroundColor('#550000');
        } else {
            props.setCompanyInfo( data );
            setChanges(false);
            setErrors(false);
            props.setBackgroundColor(null);
            props.setChanges(false);
        }
    }

    useEffect(() => {
        setData( props.companyInfo );
    }, [props.companyInfo]);

    useEffect(() => {
        setChanges(props.changes);
    }, [props.changes]);

    return (
        <>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={8}>
                <h3>Company Details:</h3>
            </Grid>
            <Grid item xs={1}>
                <HelpTip tip={companyTip} />
            </Grid>
            <Grid item xs={3}>
                <SaveChangesButton
                    changes={changes}
                    saveChanges={saveChanges}
                />
            </Grid>

            {
                errors && (
                    <>
                        <Grid item xs={12}>
                            <Alert severity="error">{errors.message}</Alert>
                        </Grid>
                    </>
                )
            }


            <Grid item xs={12}>
                <Tooltip title={<h3>Your business name. This will be displayed in your portal.</h3>} followCursor>
                    <TextField 
                        id="companyName"
                        label="Company Name*"
                        name="name"
                        error={validation.name !== undefined}
                        helperText={validation.name}
                        defaultValue={props.companyInfo.name}
                        onChange={(e) => {handleChange(e.target.value, e.target.name)}}
                        fullWidth
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <Tooltip title={<h3>This field will be prefixed to all of your application urls. It is your company name with any spaces and special characters removed.</h3>} followCursor>
                    <Chip icon={<LanguageIcon/>} label={`${data.domain_name !== undefined ? data.domain_name : ""}.portal.mybe.software`} />
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <Tooltip title={<h3>This email address will be used to send invoices and payment updates</h3>} followCursor>
                    <TextField
                        id="companyBillingEmail"
                        label="Billing Email Address*"
                        name="billing_email"
                        error={validation.billing_email !== undefined}
                        helperText={validation.billing_email}
                        defaultValue={props.companyInfo.billing_email}
                        onChange={(e) => {handleChange(e.target.value, e.target.name)}}
                        fullWidth
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <Tooltip title={<h3>The first line address of your main business location</h3>} followCursor>
                    <TextField
                        id="companyAddress"
                        label="Company Address"
                        name="address"
                        error={validation.address !== undefined}
                        helperText={validation.address}
                        defaultValue={props.companyInfo.address}
                        onChange={(e) => {handleChange(e.target.value, e.target.name)}}
                        fullWidth
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <Tooltip title={<h3>Postcode of your main business location</h3>} followCursor>
                    <TextField
                        id="companyPostcode"
                        label="Company Postcode"
                        name="postcode"
                        error={validation.postcode !== undefined}
                        helperText={validation.postcode}
                        value={props.companyInfo.postcode}
                        onChange={(e) => {handleChange(e.target.value, e.target.name)}}
                        fullWidth
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <Tooltip title={<h3>Primary contact phone number of your business</h3>} followCursor>
                    <TextField
                        id="companyPhoneNumber"
                        label="Company Phone Number"
                        name="phone"
                        error={validation.phone !== undefined}
                        helperText={validation.phone}
                        value={props.companyInfo.phone}
                        onChange={(e) => {handleChange(e.target.value, e.target.name)}}
                        fullWidth
                    />
                </Tooltip>
            </Grid>
        </Grid>
    </>
    )
}