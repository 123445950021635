import { CircularProgress, Dialog, DialogContentText, DialogTitle, Divider, Fade, IconButton, Stack } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from "@mui/icons-material/Close";
import MoodBadIcon from '@mui/icons-material/MoodBad';

import React, { useEffect, useState } from "react";
import { appsIndex } from "../api/appService";
import EyeCatcherApp from "./EyeCatcherApp";
import "../App.css"

const viewSize = 4;

export default function EyeCatcherApps({open, setOpen, setFormStart}) {
    const [apps, setApps] = useState(false);
    const [appOffset, setAppOffset] = useState(0);
    const [errors, setErrors] = useState(false);

    const getApps = async () => {
        const a = await appsIndex();

        if(a.data === undefined) {
            setErrors("Failed to load Apps. Please refresh and try again");
        } else {
            setApps(a.data);
        }
    }

    const displayApps = () => {
        const toDisplay = apps.slice(appOffset, appOffset + viewSize);
        return toDisplay.map((app) => 
            <Fade in={true} timeout={1500} key={app.id}>
                <div>
                    <EyeCatcherApp app={app} setFormStart={setFormStart}/>
                </div>
            </Fade>
        );
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        getApps();
    }, []);
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            open={open}
            onClose={() => {setOpen(false)}}
            sx={{
                '& .MuiDialog-paper': {
                    backgroundColor: "#3e3e3ede",
                    color: "white"
                }
            }}
        >
            <DialogTitle style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <span>MYBE Apps</span>
                <IconButton onClick={() => {handleClose()}}>
                    <CloseIcon style={{ color: "white" }} />
                </IconButton>
            </DialogTitle>
            <Divider style={{ borderColor: "white" }} variant="middle"/>
            <div style={{ marginTop: "5px", padding: "10px" }}>
                <DialogContentText style={{ color: "white", textAlign: "center" }}>
                    <i>Get started for free. Signup and choose one or more Apps to trial at <b>zero</b> cost! No payment details required. </i>
                </DialogContentText>
                <div className="appContainer">
                    {
                        !apps && !errors && (
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "60vh", justifyContent: "center", alignItems: "center" }}>
                                <CircularProgress style={{ color: "white" }}/>
                                <p>Loading Apps...</p>
                            </div>
                        )
                    }
                    {
                        apps && apps.length > 0 && !errors && (
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "60vh", justifyContent: "center", alignItems: "center" }}>
                                <Stack 
                                    direction="row" 
                                    spacing={1}
                                >
                                    <IconButton
                                        onClick={() => {setAppOffset(appOffset - viewSize)}}
                                        disabled={(appOffset - viewSize) < 0}
                                    >
                                        <NavigateBeforeIcon style={{ color: (appOffset - viewSize) < 0 ? "grey" : "white", fontSize: "32pt" }}/>
                                    </IconButton>
                                    {
                                        displayApps()
                                    }
                                    <IconButton
                                        onClick={() => {setAppOffset(appOffset + viewSize)}}
                                        disabled={(appOffset + viewSize) >= apps.length}
                                    >
                                        <NavigateNextIcon style={{ color: (appOffset + viewSize) >= apps.length ? "grey" : "white", fontSize: "32pt" }}/>
                                    </IconButton>
                                </Stack>
                            </div>
                        )
                    }
                    {
                        apps && !errors && apps.length <= 0 && (
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "60vh", justifyContent: "center", alignItems: "center" }}>
                                    <MoodBadIcon style={{ color: "orange", fontSize: "48pt" }} />
                                    <p style={{ textAlign: "center" }}>
                                        No Apps Found
                                    </p>
                            </div>
                        )
                    }
                    {
                        errors && (
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "60vh", justifyContent: "center", alignItems: "center" }}>
                                <WarningAmberIcon style={{ color: "red", fontSize: "48pt" }} />
                                <p style={{ textAlign: "center" }}>
                                    An Error Has Occured:<br/>
                                    {errors}
                                </p>
                            </div>
                        )
                    }
                </div>
            </div>
        </Dialog>
    )
}