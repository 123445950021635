import React from "react";
import { Container, Grid, TextField, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

export default function Summary(props) {
    const selectedApps = Object.values(props.selectedApps);
    var appTotal = 0;

    selectedApps.map((value) => {
        appTotal += parseFloat(value.price);
    });

    const paperStyle = {
        padding: "0px 10px 10px 10px",
        margin: "10px 0px 10px 0px"
    }

    const handleFormComplete = () => {
        props.handleFormComplete();
    }

    return Object.keys(props.adminInfo).length > 0 && Object.keys(props.companyInfo).length > 0 && Object.keys(props.selectedApps).length > 0 && Object.keys(props.styleInfo).length > 0 ? (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h3>Summary</h3>
            </Grid>
            <Grid item xs={12}>
                <Paper style={ paperStyle }>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <h4 style={{ textAlign: "center" }}>Admin Info:</h4>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                label="Fullname"
                                value={props.adminInfo.fullName}
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                label="Email Address"
                                value={props.adminInfo.email}
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                label="Phone Number"
                                value={props.adminInfo.phone}
                                disabled
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper style={ paperStyle }>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <h4 style={{ textAlign: "center" }}>Company Info:</h4>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                label="Company Name"
                                value={props.companyInfo.name}
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                label="Billing Email Address"
                                value={props.companyInfo.billing_email}
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                label="Company Address"
                                value={props.companyInfo.address}
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                label="Company Postcode"
                                value={props.companyInfo.postcode}
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                label="Company Phone Number"
                                value={props.companyInfo.phone}
                                disabled
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper style={ paperStyle }>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <h4 style={{ textAlign: "center" }}>Apps:</h4>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Users</TableCell>
                                        <TableCell align="right">Band</TableCell>
                                        <TableCell align="right">Sub Total (£)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    selectedApps.map((value, key) => {
                                        return (
                                        <TableRow key={key}>
                                            <TableCell>{value.name}</TableCell>
                                            <TableCell align="right">{value.users !== undefined ? value.users : <ClearIcon/>}</TableCell>
                                            <TableCell align="right">{value.band !== undefined ? value.band : <ClearIcon/>} {value.band_tag !== undefined ? value.band_tag : ""}</TableCell>
                                            <TableCell align="right">£{value.price}</TableCell>
                                        </TableRow>
                                        )
                                    })
                                }
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{
                                textAlign: "center",
                                background: "green",
                                color: "white",
                                borderRadius: "20px",
                                padding: "1.5px"
                            }}>
                                <p>Total: £{appTotal.toFixed(2)} Per Month</p>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper style={ paperStyle }>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <h4 style={{ textAlign: "center" }}>Style:</h4>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ textAlign: "center" }}>
                                <CheckIcon color="success" />
                                <p>Theme Selected</p>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                props.styleInfo.logo !== undefined ? (
                                    <div style={{ textAlign: "center" }}>
                                        <CheckIcon color="success"/>
                                        <p>Logo Uploaded</p>
                                    </div>
                                ) : (
                                    <div style={{ textAlign: "center" }}>
                                        <ClearIcon color="error" />
                                        <p>Logo Not Uploaded</p>
                                    </div>
                                )
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper style={ paperStyle }>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={4}>
                            <FormControlLabel control={
                                <Checkbox 
                                    defaultChecked={props.trial}
                                    size="large"
                                    onChange={(e, checked) => {props.handleTrialChange(checked)}}
                                />
                            } label="Free Trial"/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{ textAlign: "center" }}>
                                Claim a 30 day free trial. Pay nothing for 30 days then carry your data over when you set up payment.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper style={ paperStyle }>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={(e) => {handleFormComplete()}}
                                fullWidth
                            >
                                <SaveIcon/> Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    ) : (
        <Container>
            <h4 style={{ textAlign: "center" }}>Sections Incomplete</h4>
            <p><i>Please Complete the Following Sections Before Submitting:</i></p>
            <ul>
                {
                    Object.keys(props.adminInfo).length <= 0 && (<li>Admin Info</li>)
                }
                {
                    Object.keys(props.companyInfo).length <= 0 && (<li>Company Info</li>)
                }
                {
                    Object.keys(props.selectedApps).length <= 0 && (<li>Apps</li>)
                }
                {
                    (Object.keys(props.styleInfo).length <= 0 || Object.keys(props.styleInfo.sidebar).length <= 0) &&(<li>Style</li>)
                }
            </ul>
        </Container>
    )
}