import React, { useState } from "react";

import { Dialog, AppBar, Tooltip, Toolbar, IconButton, Grid, Container, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';

export default function AppInfo(props) {
    const [ open, setOpen ] = useState(false);
    const [ imageOpen, setImageOpen ] = useState(false);
    const [ selectedImage, setSelectedImage ] = useState("");

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleImageSelect = async (imageURL) => {
        await setSelectedImage( imageURL );
        setImageOpen(true);
    }

    const handleImageClose = () => {
        setSelectedImage( "" );
        setImageOpen(false);
    }

    return (
        <>
            <Tooltip arrow title={`Click for more info on ${props.app.name}`}>
                <IconButton color="primary" onClick={handleOpen}>
                    <HelpIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                scroll="body"
            >
                <div className="infoDialog">
                <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: "#24356a" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                        <h5>{`${props.app.name} Details`}</h5>
                    </Toolbar>
                </AppBar>
                
                <Container style={{ padding: "20px" }}>
                    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={6}>
                            {
                                props.app.icon_url !== undefined || props.app.icon_url !== null ?(
                                    <img src={props.app.icon_url} width={124} height={124}
                                    alt="App Icon" className="app_icon" />
                                ) : (
                                    <></>
                                )
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <h2>{props.app.name}</h2>
                        </Grid>
                        <Grid item xs={12}>
                            <p>{props.app.overview}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} className="feature-container">
                            {
                                (props.app.features !== undefined || props.app.features !== []) &&Array.isArray(props.app.features) && props.app.features.map((feature, key) => {
                                    return (
                                        <Grid item xs={12} md={6} key={key}  className="feature-block">
                                        <Paper className="feature-content">
                                        <DoneIcon className="feature-icon"/> 
                                        <p>{feature}</p>
                                        </Paper>
                                        </Grid>
                                    )
                                })
                            }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div dangerouslySetInnerHTML={{__html: props.app.description}}></div>
                        </Grid>
                        {
                            props.app.screenshot_urls !== undefined && props.app.screenshot_urls !== [] && (
                                <Grid item xs={12}>
                                <Grid container spacing={2} justifyContent="center" alignItems="center" className="screenshot-container">
                                    {
                                        props.app.screenshot_urls.map((screen, key) => {
                                            return (
                                                <Grid item xs={12} md={6} key={key} className="screenshot-item">
                                                    <div>
                                                    <img
                                                        className="screenshot_preview"
                                                        src={screen}
                                                        width="239"
                                                        height="118"
                                                        alt={`${props.app.name} screenshot number ${key}`}
                                                        loading="lazy"
                                                        onClick={() => handleImageSelect(screen)}
                                                    />
                                                    </div>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                                </Grid>
                            )
                        }
                        <Grid item xs={12}>
                            <h4 style={{ textAlign: "center" }}>Pricing</h4>
                            {
                                props.app.banded_cost ? (
                                    <>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Band ({props.app.band_tag})</TableCell>
                                                    <TableCell>Cost (£)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.app.bands.map((band, key) => {
                                                    return (
                                                        <TableRow key={key}>
                                                            <TableCell>
                                                                {band}
                                                            </TableCell>
                                                            <TableCell>
                                                                {props.app.band_prices[key] !== undefined ? props.app.band_prices[key] : "Contact Us For Details"}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </>
                                ) : (
                                    <>
                                        <p className="info_pricing_user">£{props.app.price} per {props.app.scale_tag}</p>
                                    </>
                                )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {
                                props.added ? (
                                    <Button 
                                        variant="contained"
                                        color="error"
                                        fullWidth
                                        onClick={() => {
                                            props.removeApp();
                                            handleClose();
                                        }}
                                    >
                                        <CloseIcon/>
                                        Remove from Package
                                    </Button>
                                ) : (
                                    <Button 
                                        variant="contained"
                                        color="success"
                                        onClick={() => {
                                            props.addApp();
                                            handleClose();
                                        }}
                                        fullWidth
                                    >
                                        <AddIcon/>
                                        Add to Package
                                    </Button>
                                )
                            }

                        </Grid>
                    </Grid>
                </Container>
                </div>
            </Dialog>
            <Dialog
                fullScreen
                open={imageOpen}
                onClose={handleImageClose}
            >
                <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: "#24356a" }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleImageClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <h5>{`${props.app.name} Screenshot`}</h5>
                </Toolbar>
            </AppBar>

            <img
                src={selectedImage}
                height="auto"
                width="auto"
                alt="Full Screenshot"
                loading="lazy"
            />

            </Dialog>
        </>
    )
}